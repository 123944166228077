$(() => {
    $("#txtKitchenExpense").on("keyup", () => {
        ClearKitchenRentIncrease();
        CalculateKitchenRentIncrease();
    });

    $("#txtBathroomExpense").on("keyup", () => {
        ClearBathroomRentIncrease();
        CalculateBathroomRentIncrease();
    });

    $("#txtKitchenRentIncrease").on("keyup", () => {
        ClearKitchenExpense();
        CalculateKitchenInternalRentIncrease();
    });

    $("#txtBathroomRentIncrease").on("keyup", () => {
        ClearBathroomExpense();
        CalculateBathroomInternalRentIncrease();
    });
});

function CalculateBathroomInternalRentIncrease() {

    try {
        const bathroomRentIncreaseString = $("#txtBathroomRentIncrease").val() as string;
        if (bathroomRentIncreaseString === "") throw "";

        if (!$.isNumeric(bathroomRentIncreaseString)) {
            $("#bathroomRentIncreaseValidationMessage").html("Input must be numeric.");
            throw "";
        }
        else {
            $("#bathroomRentIncreaseValidationMessage").html("");
        }

        const bathroomRentIncrease = parseFloat(bathroomRentIncreaseString);
        const bathroomLoans = 1.1 * _estimatedExpense() * (1 + _rentPercentage() / 2) + _administrationFee();

        const internalRentIncrease = Math.ceil(bathroomLoans * _bathroomFactor());
        const bathroomCraftsmanWork = _estimatedExpense() / internalRentIncrease * bathroomRentIncrease;

        let bathroomCraftsmanWorkString = bathroomCraftsmanWork.toFixed(2);
        bathroomCraftsmanWorkString = formatCurrency(bathroomCraftsmanWorkString);

        $("#bathroomCraftsmanWork").html(bathroomCraftsmanWorkString + " DKK");
    }
    catch (err) {
        ClearValues("Bathroom2");
    }
}

function CalculateKitchenInternalRentIncrease() {
    try {
        const kitchenRentIncreaseString = $("#txtKitchenRentIncrease").val() as string;
        if (kitchenRentIncreaseString === "")
            throw "";

        if (!$.isNumeric(kitchenRentIncreaseString)) {
            $("#kitchenRentIncreaseValidationMessage").html("Input must be numeric.");
            throw "";
        }
        else { $("#kitchenRentIncreaseValidationMessage").html(""); }

        const kitchenRentIncrease = parseFloat(kitchenRentIncreaseString);
        const kitchenLoans = 1.1 * _estimatedExpense() * (1 + _rentPercentage() / 2) + _administrationFee();

        const internalRentIncrease = Math.ceil(kitchenLoans * _kitchenFactor());
        const kitchenCraftsmanWork = _estimatedExpense() / internalRentIncrease * kitchenRentIncrease;
        let kitchenCraftsmanWorkString = kitchenCraftsmanWork.toFixed(2);
        kitchenCraftsmanWorkString = formatCurrency(kitchenCraftsmanWorkString);

        $("#kitchenCraftsmanWork").html(kitchenCraftsmanWorkString + " DKK");
    }
    catch (err) {
        ClearValues("Kitchen2");
    }
}

function CalculateBathroomRentIncrease() {
    try {
        const bathroomExpenseString = $("#txtBathroomExpense").val() as string;
        if (bathroomExpenseString === "")
            throw "";

        if (!$.isNumeric(bathroomExpenseString)) {
            $("#bathroomExpenseValidationMessage").html("Input must be numeric.");
            throw "";
        }
        else { $("#bathroomExpenseValidationMessage").html(""); }

        const bathroomExpense = parseFloat(bathroomExpenseString);
        const bathroomLoans1 = 1.1 * bathroomExpense * (1 + _rentPercentage() / 2) + _administrationFee();

        const rentIncrease = Math.ceil(bathroomLoans1 * _bathroomFactor());
        const rentIncreaseString = formatCurrency(rentIncrease.toString());

        //Populate values on textboxes/fields
        $("#bathroomRentIncrease").html(rentIncreaseString + " DKK");
    }
    catch (err) {
        ClearValues("Bathroom");
    }
}

function CalculateKitchenRentIncrease() {
    try {
        const kitchenExpenseString = $("#txtKitchenExpense").val() as string;
        if (kitchenExpenseString === "")
            throw ""; //Tjek om value indeholder noget

        if (!$.isNumeric(kitchenExpenseString)) {
            $("#kitchenExpenseValidationMessage").html("Input must be numeric.");
            throw ""; // tjek om value er et tal
        }
        else { $("#kitchenExpenseValidationMessage").html(""); }

        const kitchenExpense = parseFloat(kitchenExpenseString); //Konverter til float
        const kitchenLoans = 1.1 * kitchenExpense * (1 + _rentPercentage() / 2) + _administrationFee();

        const rentIncrease = Math.ceil(kitchenLoans * _kitchenFactor());
        const rentIncreaseString = formatCurrency(rentIncrease.toString());

        //Populate values on textboxes/fields
        $("#kitchenRentIncrease").html(rentIncreaseString + " DKK");
    }
    catch (err) {
        ClearValues("Kitchen");
    }
}

var _rentPercentage = () => 1 / 100;

var _administrationFee = () => 2500;

var _paybackTimeKitchen = () => 180;

var _paybackTimeBathroom = () => 240;

var _kitchenFactor = () => (_rentPercentage() / 12) / (1 - (1 / Math.pow((1 + (_rentPercentage() / 12)), _paybackTimeKitchen())));

var _bathroomFactor = () => (_rentPercentage() / 12) / (1 - (1 / Math.pow((1 + (_rentPercentage() / 12)), _paybackTimeBathroom())));

var _estimatedExpense = () => 50000;

function ClearValues(tab: any);
function ClearValues(tab) {
    switch (tab) {
        case 'Kitchen':
            $("#kitchenRentIncrease").html("0 DKK");
            break;
        case 'Kitchen2':
            $("#kitchenCraftsmanWork").html("0 DKK");
            break;
        case 'Bathroom':
            $("#bathroomRentIncrease").html("0 DKK");
            break;
        case 'Bathroom2':
            $("#bathroomCraftsmanWork").html("0 DKK");
            break;
    }
}

const formatCurrency = (currency: string, withDecimal: boolean = false) => {

    var decimalPos = currency.indexOf(".");
    var wholeNumber = currency.substring(0, decimalPos);

    var formattedCurrency = "";
    if (withDecimal) {
        const decimal = currency.substring(decimalPos + 1);
        formattedCurrency = formattedCurrency + "," + decimal;
    }
    else {
        const currencyNumber: number = +currency; //convert to number
        wholeNumber = Math.round(currencyNumber).toString();
    }

    while (wholeNumber.length > 3) {
        formattedCurrency = "." + ChunkWholeNumber(wholeNumber) + formattedCurrency;
        wholeNumber = wholeNumber.substring(0, wholeNumber.length - 3);
    }

    formattedCurrency = wholeNumber + formattedCurrency;

    return formattedCurrency;
}

function ChunkWholeNumber(wholeNumber: any);

function ChunkWholeNumber(wholeNumber) {
    return wholeNumber.substring(wholeNumber.length - 3);
}

function ClearKitchenExpense() {
    $("#kitchenRentIncrease").html("0 DKK");
    $("#txtKitchenExpense").val("");
}

function ClearBathroomExpense() {
    $("#bathroomRentIncrease").html("0 DKK");
    $("#txtBathroomExpense").val("");
}

function ClearKitchenRentIncrease() {
    $("#kitchenCraftsmanWork").html("0 DKK");
    $("#txtKitchenRentIncrease").val("");
}

function ClearBathroomRentIncrease() {
    $("#bathroomCraftsmanWork").html("0 DKK");
    $("#txtBathroomRentIncrease").val("");
}